// @flow

import * as React from 'react'

import * as styles from './PCRSamplePrep.module.css'

import {BulletPoint} from '../../ui-components'

import {WORKFLOW_BULLET_POINT_DATA} from './pcr_sample_prep-data'

export default function Overview() {
  return (
    <div className={styles.overview_container}>
      <section>
        <h4 className={styles.h4} id="overview">
          Overview
        </h4>
        <p className={styles.p}>
          Automated preparation of PCR and qPCR reactions is the single most
          popular application on the Opentrons platform, needed and used by
          hundreds of biologists worldwide. This workhorse protocol provides
          valuable data, but requires repetitive and precise liquid-handling to
          set up—making it a perfect lab process to automate.
        </p>
        <br />
        <p className={styles.p}>
          The most effective way to automate this process is for the OT-2 to
          combine samples and PCR mastermix in the reaction plate that will go
          into the thermocycler or qPCR machine. The mastermix should be
          prepared prior to this process, either by hand or by also using the
          OT-2 robot. More details on this process are below.
        </p>
        <div className={styles.workflow}>
          <p className={styles.p}>
            This workflow can be used for the following applications:
          </p>
          <div>
            <BulletPoint names={WORKFLOW_BULLET_POINT_DATA} />
          </div>
        </div>
      </section>
    </div>
  )
}
