// @flow

import * as React from 'react'

import {Validation} from '../../ui-components'

import * as styles from './PCRSamplePrep.module.css'

const VALIDATION_DATA =
  'This application has been fully tested and scientifically verified by Opentrons.'

export default function Verification() {
  return (
    <div className={styles.verification}>
      <Validation content={VALIDATION_DATA} badge={true} />
    </div>
  )
}
