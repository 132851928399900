// @flow

import * as React from 'react'
import {Link} from 'gatsby'

import * as styles from './PCRSamplePrep.module.css'

import {Note} from '../../ui-components'

export const NOTE_DATA = [
  {
    name: 'Reagents',
    note: (
      <p>
        The use of mastermixes that contain many components of PCR, including
        polymerase, dNTPs and buffer, reduce the run time and pipetting steps. A
        ‘super-mastermix’ that includes the mastermix along with the forward and
        reverse primers minimizes pipetting steps, requiring only one tip per
        sample. We recommend this streamlined approach for setting up PCR on the
        OT-2. Samples in PCR strips and 96-well PCR plates can be in a
        pre-cooled aluminum block. Reagents can be kept at 4ºC on the{' '}
        <Link className={styles.links} to="/modules/temperature-module/">
          Temperature Module
        </Link>{' '}
        while running the protocol on the OT-2.
      </p>
    ),
  },
  {
    name: 'Labware',
    note: (
      <p>
        The OT-2 supports various labware for PCR. We recommend{' '}
        <a
          className={styles.links}
          target="_blank"
          rel="noopener noreferrer"
          href="https://shop.opentrons.com/nest-0-1-ml-96-well-pcr-plate-full-skirt/"
        >
          full skirted 96-well PCR plates
        </a>{' '}
        for easy and secure placement on the deck. Due to the variety of
        dimensions across PCR plates we encourage users to find a labware
        definition that closely matches the labware you already have. In
        addition, several examples of PCR labware defined in our software are
        illustrated in Figure 1. Alternatively, the ‘labware.create’ command can
        be used to input the exact dimensions of your PCR plate. Refer to the{' '}
        <a
          className={styles.links}
          target="_blank"
          rel="noopener noreferrer"
          href="https://labware.opentrons.com/"
        >
          Labware Library
        </a>{' '}
        for more details about supported and custom labware on the OT-2.
      </p>
    ),
  },
  {
    name: 'Pipettes',
    note: (
      <p>
        The p10, p50 and p300 pipettes in single and multi-channel formats can
        accommodate total reaction volumes of PCR experiments from 10 to 200 μL.
        For more information on our pipettes and pipetting volumes as low as 1
        μL refer to our{' '}
        <a
          className={styles.links}
          href="https://s3.amazonaws.com/opentrons-landing-img/pipettes/OT-2-Pipette-White-Paper.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          OT-2 Pipette White Paper.
        </a>{' '}
      </p>
    ),
  },
]

export default function Notes() {
  return (
    <div>
      <section className={styles.notes}>
        <h4 className={styles.h4} id="notes">
          Notes
        </h4>
        {NOTE_DATA.map((note, i) => {
          return <Note key={i} {...note} />
        })}
      </section>
    </div>
  )
}
