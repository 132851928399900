// @flow

import * as React from 'react'

import Hero from './Hero'
import StickyMenu from './StickyMenu'
import Video from './Video'
import Overview from './Overview'
import Benefits from './Benefits'
import Workflow from './Workflow'
import Verification from './Verification'
import ApplicationData from './ApplicationData'
import OpentronsMaterials from './OpentronsMaterials'
import Notes from './Notes'
import Resources from './Resources'
import Related from './Related'
import Community from '../homepage/Community'

import {LightBox} from '../../ui-components'
import {MENU_DATA, SCROLL_MENU_DATA} from './pcr_sample_prep-data'

import * as styles from './PCRSamplePrep.module.css'

const userVideo = `https://player.vimeo.com/video/321420718`

export default function PCRSamplePrep() {
  const [videoOpen, setVideoOpen] = React.useState(false)
  return (
    <>
      <Hero />
      <div className={styles.container}>
        <StickyMenu
          {...MENU_DATA}
          scrollSpyItems={SCROLL_MENU_DATA}
          resetMenu={true}
        />
        <div className={styles.pcr_content}>
          <Video onClick={() => setVideoOpen(true)} />
          <Overview />
          <Benefits />
          <Workflow />
          <Verification />
          <ApplicationData />
          <OpentronsMaterials />
          <Notes />
          <Resources />
        </div>
        {videoOpen && (
          <LightBox
            videoSrc={userVideo}
            onCloseClick={() => setVideoOpen(false)}
          />
        )}
      </div>
      <Related />
      <Community />
    </>
  )
}
