import {PRICES} from '../../prices'

export const WORKFLOW_DATA = [
  {
    imgSrc: require('./assets/step-1_200x150.png').default,
    stepNumber: '1',
    name: 'Design your protocol',
    content:
      'Start from scratch with the Protocol Designer, or use a Protocol Library template',
  },
  {
    imgSrc: require('./assets/step-2_200x150.png').default,
    stepNumber: '2',
    name: 'Place reagents onto robot',
    content:
      'Put premade PCR mastermix on the robot—or have the OT-2 make it for you. Combine mastermix with forward and reverse primers.',
  },
  {
    imgSrc: require('./assets/step-3_200x150.png').default,
    stepNumber: '3',
    name: 'Calibrate & activate protocol',
    content: 'Confirm pipetting locations & hit "run" (takes < 5 minutes)',
  },
  {
    imgSrc: require('./assets/step-4_200x150.png').default,
    stepNumber: '4',
    name: 'Load thermocycler & run',
    content:
      'After the OT-2 has prepped all your reactions, just run your PCR machine',
  },
]

export const MATERIAL_DATA = [
  {
    url: 'https://shop.opentrons.com/ot-2-robot/',
    imgSrc: require('../../images/application/materials/opentrons_ot2.jpg')
      .default,
    name: 'OT-2 Liquid Handling Robot',
    price: 'Starting at under $10,000.00',
  },
  {
    url: 'https://shop.opentrons.com/8-channel-electronic-pipette/',
    imgSrc:
      require('../../images/application/materials/multi_channel_pipette.jpg')
        .default,
    name: '8-Channel Pipette',
    price: PRICES.MultiChannelPipette,
  },
  {
    url: 'https://shop.opentrons.com/products/tube-rack-set-1/',
    imgSrc: require('../../images/application/materials/4_1_tube_rack.jpg')
      .default,
    name: '4-in-1 Tube Rack Set',
    price: '$200.00',
  },
  {
    url: 'https://shop.opentrons.com/universal-filter-tips/products/opentrons-300ul-tips/',
    imgSrc: require('../../images/application/materials/filter_tips.jpg')
      .default,
    name: '300 µL Tip Rack (incl. 9,600 tips)',
    price: '$500.00',
  },
  {
    url: 'https://shop.opentrons.com/products/aluminum-block-set/',
    imgSrc: require('../../images/application/materials/aluminum_block.jpg')
      .default,
    name: '96-Well Aluminum Block',
    price: '$500.00',
  },
  {
    url: 'https://shop.opentrons.com/thermocycler-module-1/',
    imgSrc:
      require('../../images/application/materials/thermocycler_module.jpg')
        .default,
    name: 'Thermocycler Module',
    price: PRICES.ThermocyclerModule,
  },
]

export const APPLICATION_DATA_TABLE_DATA = {
  headers: ['Thermocycler Model', 'Volume', 'CV (%)'],
  rows: [
    ['Opentrons', '25 μL', '10.1'],
    ['Opentrons', '10 μL', '15.2'],
    ['Opentrons', '50 μL', '11.5'],
    ['Company B', '10 μL', '14.9'],
    ['Company B', '100 μL', '15.1'],
    ['Company T', '10 μL', '22'],
    ['Company T', '100 μL', '21.6'],
  ],
}

export const NOTE_DATA = [
  {
    name: 'Reagents',
    note: 'The use of mastermixes that contain many components of PCR, including polymerase, dNTPs and buffer, reduce the run time and pipetting steps. A ‘super-mastermix’ that includes the mastermix along with the forward and reverse primers minimizes pipetting steps, requiring only one tip per sample. We recommend this streamlined approach for setting up PCR on the OT-2. Samples in PCR strips and 96-well PCR plates can be in a pre-cooled aluminum block. Reagents can be kept at 4ºC on the Temperature Module while running the protocol on the OT-2.',
  },
  {
    name: 'Labware',
    note: 'The OT-2 supports various labware for PCR. We recommend full skirted 96-well PCR plates for easy and secure placement on the deck. Due to the variety of dimensions across PCR plates we encourage users to find a labware definition that closely matches the labware you already have. In addition, several examples of PCR labware defined in our software are illustrated in Figure 1. Alternatively, the ‘labware.create’ command can be used to input the exact dimensions of your PCR plate. Refer to the Labware Library for more details about supported and custom labware on the OT-2.',
  },
  {
    name: 'Pipettes',
    note: 'The p10, p50 and p300 pipettes in single and multi-channel formats can accommodate total reaction volumes of PCR experiments from 10 to 200 μL. For more information on our pipettes and pipetting volumes as low as 1 μL refer to our OT-2 Pipette White Paper.',
  },
]

export const RESOURCE_NOTE_DATA = [
  {
    url: 'https://s3.amazonaws.com/opentrons-landing-img/modules/magnetic/Opentrons.Magnetic.Module.White.Paper.pdf',
    noteType: 'Technical Note',
    name: 'PCR Prep',
    iconName: 'pdf-download',
  },
  {
    url: 'https://protocols.opentrons.com/protocol/omega_biotek_magbind_totalpure_NGS/',
    noteType: 'Protocol',
    name: 'PCR Preparation',
    iconName: 'protocol',
  },
]

export const RELATED_CONTENT_DATA = [
  {
    url: 'https://blog.opentrons.com/the-power-of-open-source-in-biology-damp-labs-10k-biofoundry/',
    readInfo: 'BLOG - 6 MIN READ',
    title: `The Power of Open Source in Biology—DAMP Lab’s $10k Biofoundry`,
    content:
      'The DAMP Lab is a fledgling biofoundry within the new Biological Design Center at Boston University.',
  },
  {
    url: 'https://blog.opentrons.com/ngs-automation/',
    readInfo: 'BLOG - 4 MIN READ',
    title: `5 Reasons to Automate your NGS Workflow`,
    content:
      'With greater speed, lower costs, and higher throughput than first generation methods, next generation sequencing (NGS) has catapulted biological research into a new era.',
  },
]

export const MENU_DATA = {
  links: [
    'overview',
    'benefits',
    'workflow',
    'verification',
    'application data',
    'opentrons materials',
    'notes',
    'resources',
  ],
  url: '/pcr-sample-prep',
  workflow: 'Interested in automating your PCR workflow?',
}

export const SCROLL_MENU_DATA = [
  'overview',
  'benefits',
  'workflow',
  'verification',
  'application_data',
  'opentrons_materials',
  'notes',
  'resources',
]

export const WORKFLOW_BULLET_POINT_DATA = [
  'Gene expression (qPCR)',
  'NGenotyping (detection)',
  'Amplification for cloning',
  'Medical, forensic, and applied sciences',
]

export const MATERIAL_BULLET_POINT_DATA = [
  'Eppendorf 2-mL microcentrifuge tube',
  'USAScientific 8-tube PCR strip',
  'NEB Lambda DNA',
  'Forward & Reverse Primers',
  'Corning Molecular Biology Grade Water',
  'abm 2X PCR HotStart Mastermix',
  'BioGene Thermal Cycler',
]

export const BENEFIT_LIST_DATA = [
  {
    text: 'Increased speed',
  },
  {
    text: 'Maximum accuracy',
  },
  {
    text: 'Increased data consistency, reliability',
  },
  {
    text: 'Precise pipetting, with fewer steps',
  },
  {
    text: 'Prep hundreds of samples in a single run',
  },
]
