// @flow

import * as React from 'react'
import {Link} from 'gatsby'

import * as styles from './PCRSamplePrep.module.css'

import {ContentHalf, RelatedContent, Button} from '../../ui-components'

import {RELATED_CONTENT_DATA} from './pcr_sample_prep-data'

const relatedButtonProps = {
  children: 'Nucleic Acid Purification',
  Component: Link,
  to: '/nucleic-acid-cleanup',
  outline: true,
}

export default function Related() {
  return (
    <div className={styles.related_bg_color}>
      <section className={styles.related_container}>
        <div className={styles.related}>
          <div className={styles.related_application}>
            <h4 className={styles.h4}>Related Applications</h4>
            <Button className={styles.related_button} {...relatedButtonProps} />
            <h4 className={styles.h4}>Related Content</h4>
            <div className={styles.related_content_card}>
              {RELATED_CONTENT_DATA.map((relatedContent, i) => {
                return (
                  <ContentHalf key={i}>
                    <RelatedContent {...relatedContent} />
                  </ContentHalf>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
