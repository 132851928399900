// @flow

import * as React from 'react'

import * as styles from './PCRSamplePrep.module.css'

import {WorkFlow} from '../../ui-components'

import {WORKFLOW_DATA} from './pcr_sample_prep-data'

export default function Workflow() {
  return (
    <div>
      <section className={styles.nac_workflow}>
        <h4 className={styles.h4} id="workflow">
          Workflow
        </h4>
        <p className={styles.p}>
          PCR preparation on the OT-2 allows for a simplified workstream that
          can accommodate low and high throughput PCR experiments. Examples of
          precise, high yield PCR experiments are shown in the technical note
          below, along with important parameters for efficiently adapting PCR
          experiments on the OT-2. The most effective way to automate this
          process is for the OT-2 to combine samples and PCR mastermix in the
          reaction plate so they can go into your thermocycler or qPCR machine.
          The mastermix should be prepared prior to this process, either by hand
          or on the OT-2. More details on this process are below.
        </p>
        <h5 className={styles.workflow_header}>Instructions</h5>
        {WORKFLOW_DATA.map((workflow, i) => {
          return <WorkFlow key={i} {...workflow} />
        })}
      </section>
    </div>
  )
}
