// @flow

import * as React from 'react'

import * as styles from './PCRSamplePrep.module.css'

import {APPLICATION_DATA_TABLE_DATA} from './pcr_sample_prep-data'

export default function ApplicationData() {
  return (
    <div>
      <section className={styles.application_data}>
        <h4 className={styles.h4} id="application_data">
          Application Data
        </h4>
        <p className={styles.p}>
          The Opentrons Thermocycler is capable of achieving uniform PCR
          products across 96 wells. This is highly competitive with other
          thermocyclers on the market, with a CV of only 10.1% (Table 1)
          compared to the average of 10.3% observed across many competitor
          thermocyclers (1). This well-to-well uniformity is essential for
          researchers to be able to compare results from different experiments
          and samples in wells throughout the Opentrons Thermocycler.
        </p>
        <p className={styles.p}>
          <table className={styles.table}>
            <thead>
              <tr>
                {APPLICATION_DATA_TABLE_DATA.headers.map(header => (
                  <th key={header} scope="col" className={styles.th}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {APPLICATION_DATA_TABLE_DATA.rows.map(row => (
                <tr key={row}>
                  {row.map(cell => (
                    <td key={cell} className={styles.td}>
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          Table 1. Highly uniform PCR products generated on the Opentrons
          Thermocycler. Amplification was completed in a Nest full skirt plate
          with 10 - 50 μL reaction volumes. The bottom table depicts the CV% at
          different volumes, with the best performance using 25 μL.
        </p>
      </section>
    </div>
  )
}
