// extracted by mini-css-extract-plugin
export var p = "PCRSamplePrep-module--p--rA4Po";
export var h3 = "PCRSamplePrep-module--h3--Jk-fa";
export var h4 = "PCRSamplePrep-module--h4--tmuEs";
export var h5 = "PCRSamplePrep-module--h5--nJ+zU";
export var hero = "PCRSamplePrep-module--hero--fz04y";
export var hero_container = "PCRSamplePrep-module--hero_container--rx+bU";
export var validation = "PCRSamplePrep-module--validation--ixsf0";
export var hero_h2 = "PCRSamplePrep-module--hero_h2--54E-A";
export var container = "PCRSamplePrep-module--container--uRKcd";
export var desktop_menu = "PCRSamplePrep-module--desktop_menu--baHMu";
export var fixed_scroll = "PCRSamplePrep-module--fixed_scroll--6WP6w";
export var menu = "PCRSamplePrep-module--menu--zUOQc";
export var list = "PCRSamplePrep-module--list--80c4U";
export var callback = "PCRSamplePrep-module--callback--LQsFj";
export var button = "PCRSamplePrep-module--button--9PPUJ";
export var links = "PCRSamplePrep-module--links--PDQuB";
export var pcr_content = "PCRSamplePrep-module--pcr_content--RvHo1";
export var video = "PCRSamplePrep-module--video--+5wir";
export var video_details = "PCRSamplePrep-module--video_details--TdnEH";
export var video_container = "PCRSamplePrep-module--video_container--rT+j+";
export var figure = "PCRSamplePrep-module--figure--OLrb5";
export var play_icon = "PCRSamplePrep-module--play_icon--Lwevr";
export var overview_container = "PCRSamplePrep-module--overview_container--OHNEs";
export var bold = "PCRSamplePrep-module--bold--Y2siO";
export var workflow = "PCRSamplePrep-module--workflow--+ntiX";
export var bullet_blue = "PCRSamplePrep-module--bullet_blue--vCRRb";
export var benefits = "PCRSamplePrep-module--benefits--eLbMw";
export var nac_workflow = "PCRSamplePrep-module--nac_workflow--30fBx";
export var workflow_header = "PCRSamplePrep-module--workflow_header--E+hkQ";
export var material_container = "PCRSamplePrep-module--material_container--AIipy";
export var additional_materials = "PCRSamplePrep-module--additional_materials--NYHBM";
export var verification = "PCRSamplePrep-module--verification--M0PRW";
export var application_data = "PCRSamplePrep-module--application_data--nnYGm";
export var table = "PCRSamplePrep-module--table--hTH11";
export var th = "PCRSamplePrep-module--th--ETT9d";
export var td = "PCRSamplePrep-module--td--EtPp3";
export var opentrons_materials = "PCRSamplePrep-module--opentrons_materials--VttaE";
export var notes = "PCRSamplePrep-module--notes--fY73i";
export var resources_content = "PCRSamplePrep-module--resources_content--QcXrn";
export var related = "PCRSamplePrep-module--related--Pslpv";
export var related_content_h4 = "PCRSamplePrep-module--related_content_h4--0Gd6C";
export var related_application = "PCRSamplePrep-module--related_application--aGUv-";
export var related_bg_color = "PCRSamplePrep-module--related_bg_color--YpC7C";
export var related_container = "PCRSamplePrep-module--related_container--VIxQ2";
export var related_button = "PCRSamplePrep-module--related_button--mAzdy";
export var related_content_card = "PCRSamplePrep-module--related_content_card--jDi6d";