import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import PCRSamplePrep from '../components/PCRSamplePrep'

const PCRSamplePrepPage = () => (
  <Page>
    <SEO
      title="Automated PCR Sample Prep | Lab Automation from $5,000"
      description="The most effective way to automate PCR is for the OT-2 to combine samples and PCR mastermix in the reaction plate that will go into the thermocycler or qPCR machine. The mastermix should be prepared prior to this process, either by hand or by also using the OT-2 robot."
    />
    <PCRSamplePrep />
  </Page>
)

export default PCRSamplePrepPage
