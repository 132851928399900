// @flow

import * as React from 'react'

import * as styles from './PCRSamplePrep.module.css'

import {ContentHalf, ResourceNote} from '../../ui-components'

import {RESOURCE_NOTE_DATA} from './pcr_sample_prep-data'

export default function Resources() {
  return (
    <div>
      <section>
        <h4 className={styles.h4} id="resources">
          Resources
        </h4>
        <div className={styles.resources_content}>
          {RESOURCE_NOTE_DATA.map((resource, i) => {
            return (
              <ContentHalf key={i}>
                <ResourceNote {...resource} />
              </ContentHalf>
            )
          })}
        </div>
      </section>
    </div>
  )
}
